.login-footer{
  background-color: rgb(37, 47, 61);
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1.5rem;
}

.footer-login-text{
  color: #fff !important;
}

.login-logo{
  width: 10%;
}

@media (max-width: 768px) {
  .login-logo{
    width: 20%;    
  }
}

.appbar-imperium {
  flex-grow: 1 !important;
  font-family: 'Quattrocento, serif' !important;
}

.btn-capitalize {
  text-transform: capitalize !important;
}

.custom-primary-btn {
  border: none;
  background-color: transparent;
  color: #006c9b;
}

.custom-primary-btn:hover {
  background-color: rgba(234, 234, 234);
  border-radius: 5px;
  color: #006c9b;
}

.custom-primary-btn:focus {
  outline: none;
  background-color: transparent;
  box-shadow:0 0 0 .2rem rgba(234, 234, 234);
  border-radius: 5px;
  color: #006c9b;
}

.custom-danger-btn {
  border: none !important;
  background-color: transparent !important;
  color: #dc3545 !important;
}

.custom-danger-btn:hover {
  background-color: rgba(234, 234, 234) !important;
  border-radius: 5px;
  color: #dc3545;
}

.custom-danger-btn:focus {
  outline: none;
  background-color: transparent;
  box-shadow:0 0 0 .2rem rgba(234, 234, 234);
  border-radius: 5px;
  color: #dc3545;
}

.btn-outline-danger {
  border: none;
  background-color: transparent;
  color: #dc3545;
}

.btn-outline-danger:hover {
  background-color: rgba(234, 234, 234);
  border-radius: 5px;
  color: #dc3545;
}

.btn-outline-danger:focus {
  outline: none;
  box-shadow:0 0 0 .2rem rgba(234, 234, 234);
  border-radius: 5px;
  color: #dc3545;
}

.btn-outline-danger:active {
  outline: none;
  box-shadow:0 0 0 .2rem rgba(234, 234, 234);
  border-radius: 5px;
  color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger:not(:disabled):not(.disabled):active, .show>.btn-outline-danger.dropdown-toggle {
  background-color: transparent;
  color: #dc3545;
}

.container-botonera {
  border: 1px solid #fff;
  border-radius: 8px;
}